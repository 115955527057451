//*******************************************************************************
// SECTION - New Fluid Grid
//*******************************************************************************

//
// Section Default
//
.section {
	// outline: 2px solid green;
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint($min: 800px) {
		margin: 0 calc(5vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin: 0 10vw;
	}
}

//
// Section Small (Text-Container)
//
.section--extra-small {
	// outline: 2px solid blue;
	margin: 0 15vw;

	@include breakpoint(min) {
		margin: 0 calc(25vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		margin: 0 calc(25vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin: 0 30vw;
	}
}


//
// Section Small (Text-Container)
//
.section--small {
	// outline: 2px solid blue;
	margin: 0 10vw;

	@include breakpoint(min) {
		margin: 0 calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		margin: 0 calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin: 0 25vw;
	}
}

//
// Section Medium (Text-Container)
//
.section--medium {
	// outline: 2px solid blue;
	margin: 0 5vw;

	@include breakpoint(min) {
		margin: 0 calc(10vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		margin: 0 calc(10vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin: 0 12.5vw;
	}
}



//
// Section Full Viewport Width
//
.section--full,
.section.section--full {
	position: relative;
	margin: 0;
}




//
// Default Section Sapcer
//
.section,
.section--full,
.section--small {
	margin-bottom: 5vw;
}



//
// section has Image Landscape (default)
//
.section--has-image {
	@include breakpoint(min) {
		margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin-left: 20vw;
		margin-right: 20vw;
	}

	.image__desc {
		margin: 0 5vw;
		padding: 2vw 0;

		@include breakpoint(min) {
			padding: 2vw 0;
		}
	}
}



//
// Section has Bigger Landscape Image
//
.section--has-bigger-image {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint($min: 800px) {
		margin-left: calc(5vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		margin-right: calc(5vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin-left: 10vw;
		margin-right: 10vw;
	}

	.image__desc {
		padding: 2vw 0;
		margin: 0 5vw;

		@include breakpoint(min) {
			margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		}

		@include breakpoint(mid) {
			margin: 0 15vw;
		}
	}
}



//
// section has Backdrop
//
.section--has-backdrop {
	position: relative;

	@include breakpoint(mid) {
		margin-left: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin-left: 25vw;
		margin-right: 20vw;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 5vw;
		right: 5vw;
		z-index: -1;
		display: block;
		border-radius: $border-radius;
		background-color: color(coolgray100);
		width: auto;
		height: 100%;
	}

	.image__desc {
		margin: 0 5vw;
		padding: 2vw;
	}


	&.backdrop--left,
	&.backdrop--right {
		@include breakpoint(mid) {
			&::after {
				top: 5vw;
				height: calc(100% - 5vw);
			}
		}
	}

	&.backdrop--left {
		@include breakpoint(mid) {
			&::after {
				left: -5vw;
			}

			.image__desc {
				margin-left: 0;
				padding: 1vw;
			}
		}
	}

	&.backdrop--right {
		@include breakpoint(mid) {
			margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
			margin-right: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));

			&::after {
				right: -5vw;
			}

			.image__desc {
				margin-right: 0;
				padding: 1vw;
			}
		}

		@include breakpoint(max) {
			margin-left: 20vw;
			margin-right: 25vw;
		}
	}
}



//
// section has Image Portrait
//
.section--has-imageportrait {

	@include breakpoint(min) {
		margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin-left: 20vw;
		margin-right: 20vw;
	}

	.image {
		@include breakpoint(min) {
			display: grid;
			grid-template-columns: 9fr 5fr;
			grid-template-rows: auto;
		}

		@include breakpoint(max) {
			grid-template-columns: 7fr 5fr;
		}
	}

	.image__wrapper {
		@include breakpoint(min) {
			grid-column: 1 / span 1;
			grid-row: 1;
		}
	}

	.image__desc {
		margin: 0 5vw;
		padding: 2vw;

		@include breakpoint(min) {
			grid-column: 2 / span 1;
			grid-row: 1;
			align-self: end;
			margin: 0;
			padding: 5vw;
		}
	}

	// desc on the left, image right
	&.is--reversed {
		.image {
			@include breakpoint(min) {
				grid-template-columns: 5fr 9fr;
			}

			@include breakpoint(max) {
				grid-template-columns: 5fr 7fr;
			}
		}

		.image__wrapper {
			grid-column: 2 / span 1;
		}

		.image__desc {
			grid-column: 1 / span 1;
		}
	}


	&.section--has-backdrop.backdrop--right {
		@include breakpoint(min) {
			margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
			margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		}

		@include breakpoint(max) {
			margin-left: 20vw;
			margin-right: 20vw;
		}

		.image__desc {
			@include breakpoint(min) {
				padding: 5vw;
				margin-bottom: 5vw;
			}
		}

		&::after {
			@include breakpoint(min) {
				top: 5vw;
				bottom: 5vw;
				right: 0;
				left: auto;
				width: 45vw;
				height: calc(100% - 10vw);
			}
		}
	}


	&.section--has-backdrop.backdrop--left {
		.image {
			@include breakpoint(min) {
				grid-template-columns: 5fr 9fr;
			}

			@include breakpoint(max) {
				grid-template-columns: 5fr 7fr;
			}
		}

		.image__wrapper {
			@include breakpoint(min) {
				grid-column: 2 / span 1;
			}
		}

		.image__desc {
			@include breakpoint(min) {
				grid-column: 1 / span 1;
				padding: 5vw;
				margin-bottom: 5vw;
			}
		}

		&::after {
			@include breakpoint(min) {
				top: 5vw;
				left: 0;
				right: auto;
				width: 45vw;
				height: calc(100% - 10vw);
			}
		}
	}
}





//
// section has Background-Color
//
.section--has-background {
	border-radius: $border-radius;
	padding-top: 10vw;
	padding-bottom: 10vw;
	padding-left: 5vw;
	padding-right: 5vw;

	@include breakpoint(mid) {
		padding-top: 5vw;
		padding-bottom: 5vw;
		padding-left: 15vw;
		padding-right: 15vw;
	}

	&.section--small {
		margin-left: 5vw;
		margin-right: 5vw;

		@include breakpoint(min) {
			margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
			margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		}

		@include breakpoint(mid) {
			padding-left: 5vw;
			padding-right: 5vw;
		}

		@include breakpoint(max) {
			margin-left: 20vw;
			margin-right: 20vw;
		}
	}
}



//
//
//
.section--has-background.background-color--red500 {
	color: color(bright);

	a {
		color: #fff;

		&:hover {
			color: color(coolgray800);
		}
	}
}



//
// Section Spacer
//
.section.spacerbottom--zero {
	margin-bottom: 0;
}

.section.spacerbottom--small {
	margin-bottom: 2.5vw;
}

.section.spacerbottom--medium {
	margin-bottom: 5vw;
}

.section.spacerbottom--big {
	margin-bottom: 10vw;
}


@include breakpoint(0, $breakpoint-mid - 1) {
	.section.spacerbottom--sm-zero {
		margin-bottom: 0;
	}

	.section.spacerbottom--sm-small {
		margin-bottom: 2.5vw;
	}

	.section.spacerbottom--sm-medium {
		margin-bottom: 5vw;
	}

	.section.spacerbottom--sm-big {
		margin-bottom: 10vw;
	}
}


//
// Grid-Tester
//
// .gridtester {
// 	@include breakpoint(mid) {
// 		display: grid;
// 		max-width: 1280px;
// 		margin-left: auto;
// 		margin-right: auto;
// 		grid-template-columns: 9.5fr 2.5vw 8fr;
// 	}

// 	@include breakpoint(max) {
// 		grid-template-columns: 9fr 2.5vw 6.5fr;
// 		max-width: none;
// 	}

// 	.gridtester__media {
// 		outline: 1px solid red;

// 		@include breakpoint(mid) {
// 			grid-column: 1 / span 1;
// 			justify-self: end;

// 			.image {
// 				max-width: 720px;

// 			}
// 		}
// 	}

// 	.gridtester__content {
// 		outline: 1px solid blue;

// 		@include breakpoint(mid) {
// 			grid-column: 3 / span 1;
// 		}
// 	}
// }


.section.section.section--bleed {
	// Hack to not use !important
	margin-left: 0;
	margin-right: 0;
}
