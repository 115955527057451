.news101-s-s {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: repeat(2, auto);

	.swiper {
		height: fit-content;
		padding: 1em;

		.swiper-wrapper {
			width: fit-content;
		}
	}

	&__slider-ui {
		grid-column: 1/-1;
		grid-row: 3;
		margin: 24px 0;
	}

	&__slider-pagination {
		display: flex;
		justify-content: center;
	}

	&__textarea {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		grid-column: 1/-1;
		grid-row: 1;
		padding: 0 10vw;
		/* TODO::oder lieber 5vw  */
		margin-bottom: 0;

		@include breakpoint(mid) {
			margin-bottom: 0;
			padding: 0 calc(5vw + 80 * (100vw - 800px) / 800);
		}

		@include breakpoint(md) {
			padding: 0;
		}

		@include breakpoint($breakpoint-max) {
			margin-bottom: 2.5vw;
		}
	}

	&__slider {
		width: 100%;
		grid-column: 1/-1;
		grid-row: 2;
		overflow: visible;
	}

	&__swiper-wrapper {
		align-items: stretch;
	}

	&__slide {
		height: auto;
	}

	&__pagination {
		display: none;
		align-items: center;
		padding-left: 24px;

		&--visible {
			display: block;
		}
	}
}
