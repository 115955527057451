.quote201 {
	$self: &;

	position: relative;

	padding-bottom: 5vw;

	@include breakpoint(mid) {
		padding-bottom: 0;
		padding: 2.5vw 0;
		display: grid;
		grid-template-columns: 60% 40%;
	}

	// grey background
	&::before {
		content: '';
		position: absolute;
		top: 5vw;
		right: -5vw;
		bottom: 0;
		left: -5vw;
		background-color: color(coolgray100);
		border-radius: $border-radius;

		@include breakpoint(mid) {
			top: 0;
			right: 2.5vw;
			left: 0;
		}
	}

	// content over background
	&>* {
		position: relative;
	}

	&__image-wrapper {
		margin-bottom: 5vw;
		z-index: 2;

		@include breakpoint(mid) {
			margin-bottom: 0;
			order: 2
		}

		img {
			width: 100%;
			object-fit: cover;
			border-radius: $border-radius;
			overflow: hidden;
			aspect-ratio: 3 / 2;
		}
	}

	&__figure {
		z-index: 1;
		display: flex;
		flex-direction: column;
		margin-bottom: 0;

		@include breakpoint(mid) {
			order: 1;
			padding-right: 24px;
			padding-left: 24px;
		}
	}

	&__quote {
		@include scaling(font-size, (0: 0, 400: 14, 1600: 18));
		@include scaling(line-height, (0: 0, 400: 24, 1600: 32));

		&::before {
			content: '\201E';
			display: block;
			color: color(red500);
			font-family: DBHead-Black;
			font-size: 72px;
			line-height: 0.5;
			transform: translateY(-65%)
		}
	}

	&__caption {
		margin-top: auto;
		@include scaling(font-size, (0: 0, 400: 14, 1600: 16));
		line-height: 24px;
	}
}