//*******************************************************************************
// Title Component
//
//*******************************************************************************


.title {
	// outline: 1px solid black;
	margin-bottom: 0;
	padding-bottom: 12vw;

	@include breakpoint(min) {
		margin-bottom: 0;
		padding-bottom: 48px;
	}
	@include breakpoint(mid) {
		margin-bottom: 0;
		padding-bottom: 4vw;
	}
}

.title__main {
  // outline: 1px solid red;
	display: block;
	margin-bottom: 0 !important;
}

h1 .title__main {
	@include headline1;
}

h2 .title__main {
	@include headline2;
}

h3 .title__main {
  @include headline3;
}


.title__subline {
	// outline: 1px solid blue;
	@include headline2;
	display: block;
	margin-bottom: 0 !important;
	margin-top: 6vw;

	@include breakpoint(min) {
		margin-top: 24px;
	}

	@include breakpoint(mid) {
		margin-top: 2vw;
	}
}

.title__preheadline {
	// outline: 1px solid blue;
	@include font-family(headlight);
	display: block;
	margin-top: 0 !important;
	margin-bottom: 6vw;

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 2vw;
	}

	@each $size in h1, h2, h4, h5, h6 {
		&--h1 {
			@include textbiggersize();
		}

		&--bigger {
			font-size: 3vw;
		}
	}
}
