.video {
	position: relative;
}

.embed-responsive,
.video__inner {
	background-color: color(coolgray200);
	border-radius: $border-radius;
	display: block;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 100%;

	&::before {
		display: block;
		content: '';
		padding-bottom: 56.25%;
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		object-fit: contain;
	}

	video::-webkit-media-controls {
		visibility: hidden;
	}

	video::-webkit-media-controls-enclosure {
		visibility: visible;
	}
}

.video__cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: none;
	width: 100%;
	border: none;
	opacity: .7;
	transition: opacity .3s ease;

	// Special Version for own html5 Videoplayer
	video + & {
		opacity: 1;
	}
}

.video__buttonwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .1);
	transition: background-color $transition-time $transition-function;

	&:focus,
	&:hover {
		background-color: rgba(0, 0, 0, .2);
		cursor: pointer;
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: inset 0 0 0 3px color(red500);
	}


	&:focus,
	&:hover {
		.video__button,
		.teaser__playvideo {
			outline: none;
			color: #fff;
			background-color: color(red600);
			border-color: color(red600);
			text-decoration: none;
		}
	}
}

.plyr__controls__item.plyr__control {
	&:hover {
		background-color: color(bright);
		color: color(warmgray800);
	}
}

.plyr__controls__item.plyr__volume input,
.plyr__controls__item.plyr__progress__container input {
	color: color(bright);
}

.plyr__controls__item.plyr__volume button:hover {
	background-color: color(bright);
	color: color(warmgray800);
}

video {
	max-width: 100%;
}

.plyr__control--overlaid {
	background: color(bright);
	color: color(warmgray800);
}

.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: color(red600);
	color: color(bright);
}


.video__button {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video__infos {
	margin-top: 2vw;
	margin-bottom: 0;
	padding: 0 5vw;

	@include breakpoint(mid) {
		margin-top: 1vw;
		margin-bottom: 1vw;
	}
}


.video__desc,
.video__meta {
	color: color(coolgray500);
	font-size: 2vw;
	line-height: 3vw;
	margin-bottom: 0;

	@include breakpoint(mid) {
		font-size: 1vw;
		line-height: 1.5vw;
	}

	@include breakpoint(max) {
		font-size: .75vw;
		line-height: 1.5vw;
	}
}


//
// DB Consent Manager - YouTube & Vimeo
// https://consentlayer.bahn-x.de/integration-guides/consent-overlay/youtube
//
.video--db-consent {

	.db-youtube,
	.db-vimeo {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.video__cover {
		z-index: 2;
		opacity: 1;
	}

	.video__buttonwrapper {
		z-index: 3;
	}

	.consent-overlay {
		z-index: 1;
	}
}
