.teaser401 {
	overflow: hidden;
	padding-top: .2em;

	&__headline {
		padding: 0 10vw;
		margin-bottom: 30px;

		@include breakpoint($breakpoint-max) {
			margin-bottom: 2.5vw;
		}
	}

	&__slider.swiper {
		overflow: visible;
	}

	&__slide {

		@include breakpoint(0, $breakpoint-md) {
			width: 230px;
		}

		@include breakpoint($breakpoint-max) {
			width: 270px;
		}
	}

	&__slider-ui {
		margin: 24px 5vw;
	}

	&__slider-pagination {
		display: flex;
		justify-content: center;
	}
}

.footerimageandtext__css-missing {
	display: none;
}
