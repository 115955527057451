/**
* Styles for ImageGridSlider
* classnames shortened to .igs
*/
.igs {
	$self: &;

	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 12vw;

	@include breakpoint(mid) {
	  padding-bottom: 0;
	  grid-template-columns: 25fr 10vw 10fr 30fr 5vw;
	  grid-template-rows: auto 4.3vw repeat(4, auto);
	}

	&--mirrored {
	  @include breakpoint(mid) {
		grid-template-columns: 5vw 30fr 10vw 10vw 25fr;
	  }

	  #{$self} {
		&__gridarea {
		  &--top {
			padding: 4vw 12vw 0 0;

			@include breakpoint(mid) {
			  grid-column: 2 / 4;
			  padding: 0;
			}
		  }

		  &--bottom {
			grid-column: 1;

			@include breakpoint(mid) {
			  grid-column: 1 / 3;
			}
		  }

		  &--aside {
			grid-column: 2;
			padding-left: 4vw;

			@include breakpoint(mid) {
			  grid-column: 5;
			  padding-left: 0;
			}
		  }

		  &--text {
			@include breakpoint(mid) {
			  grid-column: 2 / 4;
			  grid-row: 3;
			}
		  }

		  &--navigation {

			@include breakpoint(mid) {
			  grid-column: 2 / 4;
			}
		  }
		}
	  }
	}

	// wrapper around outer section for placement of background
	&__outer-background {
	  position: relative;

	  &>* {
		z-index: 1;
		position: relative;
	  }

	  &::before {
		content: '';
		position: absolute;
		top: 40vw;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: color(coolgray100);

		@include breakpoint(mid) {
		  top: 11.25vw;
		  bottom: 11.25vw;
		}
	  }
	}

	// gridareas wrapper for sliders
	&__gridarea {
	  overflow: hidden;

	  &--top {
		grid-column: span 2;
		grid-row: 2;
		padding: 4vw 0 0 12vw;

		@include breakpoint(mid) {
		  grid-column: 3 / 5;
		  grid-row: 1 / 3;
		  padding: 0;
		}
	  }

	  &--bottom {
			min-height: 160px;
		grid-row: 1;
		align-self: flex-end;

		@include breakpoint(mid) {
		  grid-column: 4 / 6;
		  grid-row: 5;
		  padding-top: 5vw;
		}
	  }

	  &--aside {
		grid-row: 1;
		padding-right: 4vw;

		@include breakpoint(mid) {
		  grid-column: 1 / 2;
		  grid-row: 2 / 5;
		  padding-right: 0;
		}
	  }

	  &--text {
		grid-column: span 2;
		padding: 6vw 5vw;

		@include breakpoint(mid) {
		  grid-column: 3 / 5;
		  grid-row: 3;
		  padding: 5vw 0 24px;
		}
	  }

	  &--navigation {
		grid-column: span 2;
		padding: 0 5vw;

		@include breakpoint(mid) {
		  grid-column: 3 / 5;
		  grid-row: 4;
		  padding: 0;
		}
	  }
	}

	&__slider {
	  width: 100%;

	  &--image {}
	}

	//
	// images in slide
	//
	&__image {
	  object-fit: cover;
	  border-radius: $border-radius;
	  overflow: hidden;
	  width: 100%;

	  &--aside {
		aspect-ratio: 3 / 4;
	  }

	  &--top {
		// 100:53 around 2:1
		aspect-ratio: 2 / 1;
	  }

	  &--bottom {
		aspect-ratio: 4 / 3;

		@include breakpoint(mid) {
		  aspect-ratio: 2 / 1;
		}
	  }
	}

	//
	// text in slide
	//
	&__text-content {
	  &>*:last-child {
		margin-bottom: 0;
	  }
	}

	//
	// slider nav
	//
	&__navigation {
	  display: none;

	  &--visible {
		display: flex;
	  }
	}

	&__nav-button {
	  margin: 0;
	  display: flex;
	  align-items: center;
	  background-color: color(red500);
	  border: 0;
	  border-radius: $border-radius;
	  color: #fff;
	  cursor: pointer;
	  height: 48px;
	  justify-content: center;
	  transition: opacity .3s linear, background-color .3s ease;
	  width: 48px;
	  flex: 0 0 48px;

	  &:hover,
	  &:focus-visible,
	  &:active {
		background-color: color(red600)
	  }

	  &.swiper-button-disabled {
		background-color: color(coolgray200);
		cursor: not-allowed;
	  }

	  svg {
		width: 12px;
		height: 22px;
	  }

	  &+& {
		margin-left: 8px;
	  }
	}

	&__pagination {
	  display: flex;
	  align-items: center;
	  padding-left: 24px;
	}

  }
