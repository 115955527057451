.image {
	display: block;
	border: 2px solid #ccc;
	background: #eee;
	padding: 4em;
	color: #333;
	font-size: .5em;
	line-height: 1.3;
	border-radius: 15px;
	margin: 4em;
}

.image__css-missing {
	display: none;
}
