.t401card {
  $self: &;

  position: relative;
  border-radius: $border-radius-small;
  overflow: hidden;
  width: 100%;
  padding-bottom: 140%;
  transform: translateY(0);
  transition: transform $transition-time $transition-function;
  box-shadow: 0 2px 4px 0 rgba(#737373, 0.5);

  .swiper-slide-active & {
    @include breakpoint(0, $breakpoint-md) {
      transform: translateY(-18px);
    }
  }

  .swiper-slide:hover &,
  .swiper-slide:focus-within & {
    transform: translateY(-18px);
  }

  // background image element
  &__image-wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all $transition-time $transition-function;
    }

    &::before {
      opacity: 1;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 62%,
          rgba(0, 0, 0, 0.63) 84%);

      .swiper-slide-active & {
        @include breakpoint(0, $breakpoint-md) {
          opacity: 0;
        }
      }

      .swiper-slide:hover &,
      .swiper-slide:focus-within & {
        opacity: 0;
      }
    }

    &::after {
      opacity: 0;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 19%,
          rgba(0, 0, 0, 0.63) 62%);

      .swiper-slide-active & {
        @include breakpoint(0, $breakpoint-md) {
          opacity: 1;
        }
      }

      .swiper-slide:hover &,
      .swiper-slide:focus-within & {
        opacity: 1;
      }
    }
  }

  // link and also wrapper for text content
  &__text-content {
    position: absolute;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: inherit;
    text-decoration: none;
    @include scaling(padding, (0: 0, 400: 20, 800: 24));

    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
  }

  &__title {
    margin-bottom: 12px;
  }

  // content inside is hidden initially
  &__collapse {
    max-height: 0px;
    transition: all 250ms ease-in-out;

    // card collapse content visible
    .swiper-slide-active & {
      @include breakpoint(0, $breakpoint-md) {
        max-height: 380px;

        & #{$self}__collapse-item {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }

    .swiper-slide:hover &,
    .swiper-slide:focus-within &,
    .swiper-slide:focus & {
      max-height: 380px;

      & #{$self}__collapse-item {
        transform: translateY(0px);
        opacity: 1;

        &:nth-child(1) {
          transition: opacity 0.25s $transition-function 250ms,
            transform 0.25s $transition-function 250ms;
        }

        &:nth-child(2) {
          transition: opacity 0.25s $transition-function 350ms,
            transform 0.25s $transition-function 350ms;
        }
      }
    }
  }

  &__collapse-item {
    opacity: 0;
    transform: translateY(3px);
    transition: opacity 0.25s $transition-function 0s,
      transform 0.25s $transition-function 0s;
  }

  &__copy {
    @include textcompactsize();
    line-height: 1.375;
    margin-bottom: 18px;

    @include breakpoint(max) {
      font-size: 16px;
      line-height: 1.375;
    }
  }

  &__link {
    @include textcompactsize();
    line-height: 1.375;
    color: color(white);

    @include breakpoint(max) {
      font-size: 16px;
      line-height: 1.5vw;
    }

    &::after {
      @include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#ffffff" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      );
    }

    &.btn--hover,
    &.btn--pressed,
    &:focus,
    &:hover,
    &:active {
      color: color(white);

      &::after {
        @include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#ffffff" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        );
      }
    }
  }
}