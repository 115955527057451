.news101__filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2em;

    @include breakpoint(mid) { 
        flex-direction: row;
        align-items: center;
    }

    .news101__filters__tag {
        @include placeholder{ color: color(coolgray300); }
        display: block;
        margin-right: 3em;
        width: 100%;
        max-width: 400px;
        margin-bottom: 1em;
        height: 40px;
        padding: 5px 20px;
        border: 1px solid transparent;
        border-radius: 6px;
        background-color: color(coolgray100);
        color: color(black);

        @include breakpoint(mid) { 
            @include bodytextsize;
            height: 56px;
        }

        @include breakpoint(max) {
            height: 72px;
        }

        &:focus {
            outline: none;
            border: 1px solid color(coolgray300);
        }

        &.is-invalid {
            border: 1px solid color(errorred);

            &:focus {
                border: 1px solid color(coolgray300);
            }
        }

        &:-internal-autofill-selected {
            border: 1px solid color(coolgray300);
        }
        
        appearance: none;
        position: relative;
        background-size: 20px;
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBvbHlsaW5lIHRyYW5zZm9ybT0icm90YXRlKDkwLDYsNikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzRENEQ2MCIgcG9pbnRzPSIzLjcgMS44IDcuOSA2IDMuNyAxMC4yIi8+PC9zdmc+);
        cursor: pointer;
    }

    .news101__filters__type {
        margin: 0 0 1em 0;

        input {
            display: none;
        }

        span {
            display: block;
            font-size: .77em;
            padding: .65em 1.25em .65em 1em;
            margin: 0 1em 0 0;
            background-color: color(coolgray100);
            border-radius: 2em;
            line-height: 1;
            vertical-align: middle;
            cursor: pointer;
        }

        span::before {
            content: '';
            display: inline-block;
            width: 1.1em;
            height: 1.1em;
            border-radius: 3px;
            background-color: color(white);
            position: relative;
            top: -.05em;
            margin-right: .75em;
            vertical-align: middle;
			font-size: 1.2rem;
        }

        input:checked + span {
            background-color: color(red500);
            color: color(white);
        }

        input:checked + span::before {
            @include background-svg('<svg width="22" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M0 9.032c0-.58.484-1 1-1 .387 0 .484.065.71.29L7 13.582 20.258.29a.925.925 0 01.71-.291C21.548 0 22 .516 22 1c0 .258-.097.484-.323.71l-13.967 14c-.194.193-.452.29-.71.29a.925.925 0 01-.71-.29l-6-5.968a1 1 0 01-.29-.71z" fill="#000" fill-rule="nonzero"/></svg>');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 80% auto;
        }
    }
}