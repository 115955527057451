.teaser101 {
	$self: &;

	position: relative;

	@include breakpoint(0, $breakpoint-mid) {
		padding-bottom: 5vw;
	}

	@include breakpoint(mid) {
		display: grid;
	}

	// grey background
	&::before {
		content: '';
		position: absolute;
		top: 5vw;
		right: -5vw;
		bottom: 0;
		left: -5vw;
		background-color: color(coolgray100);
		border-radius: $border-radius;
	}

	// content over background
	&>* {
		position: relative;
	}


	// small as regular teaser element inside section--small
	&--small {

		@include breakpoint(mid) {
			/* padding: 3vw 0; */
			display: grid;
			grid-template-columns: 45% 55%;

			&::before {
				top: 0;
				right: 0;
				left: 2.5vw;
			}
		}


		#{$self} {
			&__image-wrapper {
				padding: 1.5vw 0;

				img {
					@include breakpoint(mid) {
						min-height: 100%;
						aspect-ratio: 3 / 2;
					}
				}
			}

			&__text {

				@include breakpoint(mid) {
					padding: 3vw 1.5vw;
				}
			}
		}
	}

	// large has larger offset image and larger headline style
	&--large {
		margin: 0 5vw;

		@include breakpoint(min) {
			margin: 0 10vw;
		}

		@include breakpoint(mid) {
			margin: 0;
			padding: 5vw 0 0;
			grid-template-columns: 43.75% 56.25%;
		}

		&::before {
			@include breakpoint(mid) {
				display: none;
			}
		}

		#{$self} {

			&__image-wrapper {
				@include breakpoint(mid) {
					figure {
						height: 100%;
						width: 100%;
						object-fit: cover;
						aspect-ratio: unset;
						transform: translateY(-5vw);
					}
					// div {
					// 	transform: translateY(-5vw);
					// }
				}
			}

			&__text {
				@include breakpoint(mid) {
					padding: 5vw;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: -15vw;
						background-color: color(coolgray100);
						border-radius: $border-radius;
					}
				}

				&>* {
					position: relative;
				}
			}

			&__overline {
				@include scaling(font-size, (0: 0, 400: 16, 1600: 36));
				@include scaling(line-height, (0: 0, 400: 24, 1600: 44));
			}

			&__headline {
				@include scaling(font-size, (0: 0, 400: 16, 1600: 36));
				@include scaling(line-height, (0: 0, 400: 24, 1600: 50));
			}
		}
	}

	&__image-wrapper {
		margin-bottom: 5vw;
		z-index: 2;

		@include breakpoint(mid) {
			margin-bottom: 0;
		}

		img {
			width: 100%;
			object-fit: cover;
			border-radius: $border-radius;
			overflow: hidden;
			box-shadow: $shadow-default;

			@include breakpoint(0, $breakpoint-mid) {
				aspect-ratio: 3 / 3.15;
			}
		}
	}

	&__text {
		z-index: 1;
		display: flex;
		flex-direction: column;
	}

	&__overline {
		@include scaling(margin-bottom, (0: 0, 400: 24, 800: 30));
	}

	&__headline {}

	&__copy {
		margin-bottom: 24px;

		@include breakpoint(mid) {
			margin-bottom: 1.5vw;
		}

	}

	&__link-wrapper {
		margin-top: auto;
	}
}
