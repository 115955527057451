.footerImageText {
  background-color: color(coolgray600);

  @include breakpoint(mid) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__image-wrapper {
    img {
      display: block;
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    padding: 24px 5vw;

    @include breakpoint(mid) {
      padding: 5vw 10vw 5vw 5vw
    }

    &>*:last-child {
      margin-bottom: 0;
    }
  }
}

.teaser401__css-missing {
  display: none;
}